<template>
    <div @click="$emit('changeImg')" v-if="videoMsg && videoMsg.url">
        <div class="videoItem" v-loading="sourceMsg.videoStatus != 'ready' && !showMask" element-loading-text="视频上传FB中...">
            <div class="videoMask" v-if="showMask">
                <el-button size="mini" type="primary" @click="retry">重试</el-button>
                <el-button size="mini" type="primary" v-if="sourceMsg.videoStatus != 'error'" @wait="wait">继续等待</el-button>
                <el-button size="mini" type="primary" @click="deleImg">取消</el-button>
            </div>
            <i class="el-icon-circle-close delete" @click.stop="deleImg" v-if="!showMask"></i>
            <video :src="videoMsg.url" controls :poster="videoMsg.thumbUrl" ref="video" @play="handlerPlay"></video>
        </div>
    </div>
</template>
<script>
import tools from '../tools'
import adsMethods from '../../adsMethods'
import uploadVideo from '../uploadVideo.vue';
import { mediaFileUploadFbCleanDate} from '@/api/creatAd.js';
export default {
    mixins: [tools, adsMethods],
    props: {
        videoMsg: {
            type: Object,
            default: () => { }
        },
        // 选中是否是当前视频
        active:{
            type:Boolean,
            default:false
        },
        index:{
            type:Number,
            default:0
        }
    },
    components: {
        uploadVideo
    },
    data() {
        return {
            sourceMsg: {
                uploadNums: 0,
                videoStatus:null,
            },
            formData: null,
            errorNotSetNull: true,
            resetUploadFlag:false,
        }
    },
    computed: {
        // sourceMsg: {
        //     get() {
        //         return { ...this.videoMsg }
        //     },
        //     set(v){
        //         this.$emit('setVideoItem',v)
        //     }
        // },
        status() {
            return this.sourceMsg.videoStatus || 'processing'
        },
        showMask() {
            return (this.sourceMsg.videoStatus == 'processing' && this.sourceMsg.uploadNums >= 12) || this.sourceMsg.videoStatus == 'error';
        }
    },
    methods: {
        deleImg() {
            this.$emit('deleImg')
        },
        retry() {
            this.sourceMsg.uploadNums = 0;
            this.resetUploadFlag = true;
            this.resetUpload()
        },
        wait(){
            this.sourceMsg.uploadNums = 0
        },
        // 重新上传
        // 清空数据库视频数据，重新上传
        resetUpload() {
            mediaFileUploadFbCleanDate(this.formData, this.targetUserId).then((res) => {
                if (res.code == 0) {
                    this.resetUploadFlag = false;
                    this.getVideoUrl(res.data.videoId);
                }
            });
        },
        setThumbList(list){
            this.$emit('setVideoItem',{videoStatus:this.sourceMsg.videoStatus})
            this.$emit('setThumbList', list,this.videoMsg.id);
        },
        handlerPlay(){
            this.$emit('changeImg',this.index)
        },
    },
    watch:{
        sourceMsg:{
            handler(){
                this.$emit('setVideoItem',this.sourceMsg)
            },
            deep:true,
            immediate:true,
        },
        active(){
            if(!this.active){
                console.log(this.$refs.video);
                this.$refs.video.pause()
            }
        }
    },
    created() {
        if(!this.videoMsg || !this.videoMsg.id) return;
        this.uploadVideoFb(this.videoMsg.id)
    },
}
</script>
<style lang="scss" scoped>
.mark-loading {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 9;
    cursor: pointer;
}

.videoItem {
    width: 100%;
    height: 100%;
    position: relative;

    .videoMask {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background: rgba($color: #fff, $alpha: 0.8);
        padding-top: 40%;
        z-index: 999;
    }

    .delete {
        position: absolute;
        top: -5px;
        right: -5px;
        z-index: 2;
        font-size: 18px;
        cursor: pointer;
        background: #fff;
        border-radius: 50%;
        z-index: 66;
    }

    video {
        width: 100%;
        height: 100%;
    }
}
</style>