<template>
  <div>
    <div v-if="currentCreative.media_type == 'video'">
      <!-- <upload-video from="fb" @beforeUpload="beforeUpload" :index="index"
                :accountId="accountId" :rowThirdNum="rowThirdNum" :adsMsg="currentCreative" @setVideoUrl="setVideoUrl"
                @setAdsMsg="setAdsMsg" @creatSlide="creatSlide" @deleImg="deleImg"
                :beforeUpload="beforeUpload"></upload-video> -->

      <!-- <video-item></video-item>
            <div class="imgForm" v-if="!isFbPageAd">
                <copywriter :creative="currentCreative" @setCreativeMsg="setImgMsg"></copywriter>
            </div> -->
      <div>
        <div>
          <div
            class="adCon"
            v-if="isFbPageAd || edit"
          >
            <!-- <div class="imgCon" v-if="!currentCreative.image">
                            <el-button size="small" @click="selectImg">从创意云选择</el-button>
                            <el-button size="small" @click="creatSlide">创建幻灯</el-button>
                            <el-upload class="upload-demo" action="#" :http-request="upload_img" :show-file-list="false"
                                :before-upload="beforeUpload">
                                <el-button size="small" type="primary">点击上传</el-button>
                                <img-tips></img-tips>
                            </el-upload>
                        </div>
                        <div class="imgCon" v-else>
                            <div class="tools">
                                <i class="el-icon-zoom-in"></i>
                                <i class="el-icon-delete" @click="deleImg"></i>
                                <el-upload class="upload-demo" action="#" :http-request="upload_img" :show-file-list="false"
                                    style="display: inline-block" :before-upload="beforeUpload">
                                    <i class="el-icon-upload2"></i>
                                </el-upload>
                            </div>
                            <el-image :src="currentCreative.image" :preview-src-list="[currentCreative.image]" fit="contain"
                                @error.once="setImg"></el-image>
                        </div> -->
            <upload-video
              from="fb"
              @beforeUpload="beforeUpload"
              :accountId="accountId"
              :rowThirdNum="rowThirdNum"
              :adsMsg="currentCreative"
              @setVideoUrl="setVideoUrl"
              @setAdsMsg="setAdsMsg"
              @creatSlide="creatSlide"
              @deleImg="deleImg"
              :beforeUpload="beforeUpload"
            ></upload-video>
            <div
              class="imgForm"
              v-if="!isFbPageAd"
            >
              <copywriter
                :creative="currentCreative"
                @setCreativeMsg="setCreativeMsg"
              ></copywriter>
            </div>
          </div>
          <div
            class="imgMsg"
            v-else
          >
            <div
              class="imgCon"
              v-if="imgList.length < canUploadNum"
            >
              <el-button
                size="small"
                @click="selectImg('video', 30)"
                >从创意云选择</el-button
              >
              <el-button
                size="small"
                @click="creatSlide"
                >创建幻灯</el-button
              >
              <el-upload
                class="upload-demo"
                action="#"
                :http-request="upload_img"
                :show-file-list="false"
                :before-upload="(v) => beforeUpload(v, true)"
                multiple
              >
                <el-button
                  size="small"
                  type="primary"
                  @click="uploadTypes = 'video'"
                  >点击上传</el-button
                >
                <div
                  slot="tip"
                  class="el-upload__tip"
                  style="line-height: 28px"
                >
                  <img-tips></img-tips>
                </div>
              </el-upload>
            </div>
            <div class="imgList">
              <video-item
                :class="['imgCon', index == activeIndex ? 'active' : '']"
                v-for="(img, index) in imgList"
                :key="`${img.id}`"
                @changeImg="() => changeImg(index)"
                :videoMsg="img"
                @deleImg="() => deleImg(index)"
                @setThumbList="setThumbList"
                @setVideoItem="(v) => setVideoItem(v, index)"
                :active="index == activeIndex"
                :index="index"
              >
                <!-- <i class="el-icon-circle-close delete" @click.stop="deleImg(index)"></i>
                                <video :src="img.url" controls></video> -->
              </video-item>
            </div>
          </div>
        </div>
        <div
          class="imgForm"
          v-if="!(isFbPageAd || edit)"
        >
          <copywriter
            v-if="imgList.length"
            :creative="currentImgMsg"
            @setCreativeMsg="setImgMsg"
          ></copywriter>
          <span
            v-else
            style="color: #f00; font-size: 12px"
            >请先上传素材再填写文案哦~~</span
          >
        </div>
      </div>
    </div>
    <span>视频封面图：</span>
    <el-button
      size="small"
      @click="selectImg('img', 1)"
      :disabled="!currentImg.source_id && !currentImg.videoStatus != 'ready'"
      >从创意云选择</el-button
    >
    <el-upload
      class="upload-demo"
      action="#"
      :http-request="uploadVideoImg"
      :show-file-list="false"
      :before-upload="beforeUpload"
      :disabled="!currentImg.source_id"
      style="display: inline-block; margin-left: 20px"
    >
      <el-button
        size="small"
        type="primary"
        @click="uploadTypes = 'img'"
        :disabled="!currentImg.source_id && !currentImg.videoStatus != 'ready'"
        >点击上传</el-button
      >
    </el-upload>
    <div
      class="adCon"
      style="margin-top: 10px"
    >
      <div
        class="thumbList"
        v-if="thumbList.length"
      >
        <div
          class="thumbWrap"
          v-for="(img, thumbIndex) in thumbList"
          :key="thumbIndex"
          @click="setVideoThumb(img.uri, thumbIndex)"
        >
          <el-image
            :src="img.uri"
            fit="contain"
          ></el-image>
          <i
            class="el-icon-circle-check"
            v-if="img.isPreferred"
          ></i>
        </div>
      </div>
      <div
        class="empty"
        v-else
        style="width: 100%"
      >
        <span>
          <i class="iconfont icon-wushuju"></i>
          <p>暂无数据</p>
        </span>
      </div>
    </div>
    <img-list
      :imgListShow="imgListShow"
      @close="imgListShow = false"
      @urlList="urlList"
      :uploadTypes="uploadTypes"
      :limit="limit"
    ></img-list>
    <creat-slide
      :adSlideShow="adSlideShow"
      @close="adSlideShow = false"
      @getVideoFile="getVideoFile"
    ></creat-slide>
  </div>
</template>
<script>
import tools from '../tools';
import adsMethods from '../../adsMethods';
import imgList from '@/views/adManagement/createAd/components/imgList';
import imgTips from './imgTips.vue';
import copywriter from './copywriter.vue';
import uploadVideo from '../uploadVideo.vue';
import videoItem from './videoItem.vue';
import creatSlide from '../creatSlide.vue';
import { uploadVideoThumb } from '@/api/creatAd.js';
import { number } from 'echarts/lib/export';
export default {
  mixins: [tools, adsMethods],
  props: {
    currentCreative: {
      type: Object,
      default: () => {},
    },
    isFbPageAd: {
      type: Boolean,
      default: false,
    },
    edit: {
      type: Boolean,
      default: false,
    },
    rowThirdNum: {
      type: String | Number,
      default: '',
    },
    accountId: {
      type: String | Number,
      default: '',
    },
  },
  components: {
    imgList,
    imgTips,
    copywriter,
    uploadVideo,
    videoItem,
    creatSlide,
  },
  data() {
    return {
      index: 0,
      imgListShow: false,
      activeIndex: 0,
      uploadTypes: 'video',
      thumbListMap: {},
      adSlideShow: false,
      uplaodType: 'video',
      defaultCopywriter: { copywriter: { title: '', content: '' }, message: '' },
      sourceMsg: {
        uploadNums: 0,
        videoStatus: null,
      },
      limit: 1,
    };
  },
  computed: {
    canUploadNum() {
      return 30;
    },
    currentImgMsg() {
      return this.imgList[this.activeIndex]?.copywriter
        ? { copywriter: this.imgList[this.activeIndex].copywriter, message: this.imgList[this.activeIndex].message }
        : { copywriter: { title: '', content: '' }, message: '' } || this.defaultCopywriter;
    },
    imgList: {
      get() {
        return this.currentCreative.imgList || [];
      },
      set(v) {
        this.setCreativeMsg(v);
      },
    },
    currentImg() {
      return this.imgList[this.activeIndex] || {};
    },
    thumbList() {
      if (this.edit || this.isFbPageAd) {
        return this.thumbListMap[this.sourceMsg.source_id] || [];
      } else {
        return this.thumbListMap[this.imgList[this.activeIndex]?.source_id] || [];
      }
    },
  },
  watch: {
    sourceMsg: {
      handler() {
        this.$emit('setCreativeMsg', { ...this.currentCreative, ...this.sourceMsg });
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    // 接收上传视频的信息（编辑）
    setAdsMsg(index, msg, isupload) {
      let message = { ...this.currentCreative, ...msg };
      this.thumbListMap[msg.source_id] = msg.thumbList;
      this.sourceMsg = { ...this.sourceMsg, ...msg };
      // this.setCreativeMsg(message)
      // this.setVideoThumb(msg.thumbList[0].uri, 0, 0, isupload);
    },
    uploadThumb(formData, type, acIndex) {
      this.$showLoading();
      // let activeIndex = acIndex || acIndex === 0 ? acIndex : this.activeIndex;
      uploadVideoThumb(formData).then((res) => {
        this.$hideLoading();
        if (res.code == 0) {
          this.$message({
            type: 'success',
            message: '上传成功',
          });
          // this.imgList[this.activeIndex].thumbUrl = res.data.thumbUrl;
          if (this.edit || this.isFbPageAd) {
            this.$set(this.sourceMsg, 'thumbUrl', res.data.thumbUrl);
          } else {
            this.$set(this.imgList[this.activeIndex], 'thumbUrl', res.data.thumbUrl);
          }
          if (type && type == 'bendi') {
            let obj = {
              isPreferred: true,
              uri: res.data.thumbUrl,
            };
            let key = this.edit || this.isFbPageAd ? this.sourceMsg.source_id : this.currentImg.source_id;
            console.log(key);
            this.thumbListMap[key].map((v) => (v.isPreferred = false));
            this.thumbListMap[key].unshift(obj);
          }
          this.$forceUpdate();
        }
      });
    },
    uploadVideoImg(file) {
      if (!this.currentImg.source_id) {
        this.$message({
          type: 'warning',
          message: '请先上传视频',
        });
        return false;
      }
      let formData = new FormData();
      formData.append('file', file.file);
      formData.append('id', this.currentImg.source_id);
      this.uploadThumb(formData, 'bendi');
    },
    // 设置封面
    setVideoThumb(url, index) {
      let key = this.edit || this.isFbPageAd ? this.sourceMsg.source_id : this.currentImg.source_id;
      if (index || index === 0) {
        this.thumbListMap[key].map((v, ind) => {
          if (index == ind) {
            this.$set(this.thumbListMap[key][ind], 'isPreferred', true);
          } else {
            this.$set(this.thumbListMap[key][ind], 'isPreferred', false);
          }
        });
      }
      let formData = new FormData();
      formData.append('url', url);
      formData.append('id', key);
      this.uploadThumb(formData, null, this.activeIndex);
    },
    setThumbList(list, id) {
      console.log('aaaaa');
      this.$set(this.thumbListMap, id, list);
      let uri = list.find((v) => v.isPreferred).uri;
      let formData = new FormData();
      formData.append('url', uri);
      formData.append('id', id);
      this.uploadThumb(formData, null, this.activeIndex);
      // this.thumbListMap[id] = list
    },
    // 创建幻灯广告
    creatSlide() {
      this.adSlideShow = true;
    },
    // 接收幻灯片视频（创建的时候）
    getVideoFile(file) {
      this.adSlideShow = false;
      this.imgList = this.imgList.concat(file).slice(0, 30);
    },
    // 接收幻灯片地址(编辑的时候)
    setVideoUrl(v, thumbList, index) {
      // console.log(v);
      this.adSlideShow = false;
      if (this.currentCreative.media_type != 'video') return false;
      let message = { ...this.currentCreative, image: v.url, source_id: v.id, videoStatus: 'processing' };
      this.setCreativeMsg(message);
      this.thumbListMap[v.id] = thumbList;
      //   this.setVideoThumb(thumbList.filter((v) => v.isPreferred)[0].uri, i);
    },
    // 点击素材库上传
    selectImg(type, num) {
      this.uploadTypes = type;
      this.limit = num || 1;
      if (this.edit || this.isFbPageAd) {
        this.limit = 1;
      }
      this.imgListShow = true;
    },
    // 修改创意
    setCreativeMsg(v) {
      if (this.isFbPageAd || this.edit) {
        this.$emit('setCreativeMsg', { ...this.currentCreative, ...v });
      } else {
        this.$emit('setCreativeMsg', { ...this.currentCreative, imgList: v });
      }
    },
    setVideoItem(v, index) {
      this.imgList[index] = { ...this.imgList[index], ...v };
      this.setCreativeMsg(this.imgList);
    },
    // 修改文案
    setImgMsg(v, key) {
      this.$set(this.imgList, this.activeIndex, { ...this.imgList[this.activeIndex], ...v });
      // 第一次填写，其他方案默认一致
      // this.imgList.forEach((item, index) => {
      //     console.log(item);
      //     if (index == this.activeIndex) return;
      //     if(key == 'title' || key == 'content'){
      //         if(!item.copywriter){
      //             this.$set(item,'copywriter',{ title: '', content: '' })
      //             if(!item.copywriter[key]) item.copywriter[key] =  v.copywriter[key]
      //         }else{
      //             if(!item.copywriter[key]) item.copywriter[key] =  v.copywriter[key]
      //         }
      //     }else{
      //         if(!item[key]) item[key] = v[key]
      //     }
      // })
    },
    // 获取素材库图片
    urlList(v) {
      if (this.isFbPageAd || this.edit) {
        if (this.uplaodTypes == 'video') {
          this.uploadVideoFb(v[v.length - 1].id);
        } else {
          this.$emit('setCreativeMsg', {
            ...this.currentCreative,
            image: v[v.length - 1].url,
            source_id: v[v.length - 1].id,
          });
        }
      } else {
        if (this.uploadTypes == 'video') {
          this.imgList = this.imgList
            .concat(
              v.map((m) => {
                return { ...m, source_id: m.id, videoStatus: 'processing' };
              }),
            )
            .slice(0, 30);
        } else {
          let img = v[v.length - 1];
          let obj = {
            isPreferred: true,
            uri: img.url,
          };
          // console.log(obj);
          this.thumbListMap[this.currentImg.source_id].unshift(obj);
          this.setVideoThumb(img.url, 0);
        }
      }
    },
    // 设置文案
    setCopyWriter(index) {
      // let defaultCopywriter = { copywriter: { title: this.defaultTilte, content: this.defaultContent }, message: this.defaultMessage }
      // this.imgList[index] = { ...this.imgList[index], ...defaultCopywriter }
      let data = this.imgList[index];
      if (!data.copywriter) {
        this.$set(data, 'copywriter', {
          title: this.defaultTitle(this.imgList),
          content: this.defaultContent(this.imgList),
        });
      } else {
        if (!data.copywriter['title']) data.copywriter['title'] = this.defaultTitle(this.imgList);
        if (!data.copywriter['content']) data.copywriter['content'] = this.defaultContent(this.imgList);
      }
      if (!data.message) data.message = this.defaultMessage(this.imgList);
    },
    // 点击选择图片切换广告语
    changeImg(index) {
      console.log(index);
      this.setCopyWriter(index);
      this.activeIndex = index;
      this.$emit('setPreviewIndex', this.activeIndex);
    },
    // 点击删除图片
    deleImg(index) {
      if (this.isFbPageAd || this.edit) {
        this.$emit('setCreativeMsg', { ...this.currentCreative, image: '' });
      } else {
        this.imgList.splice(index, 1);
        this.changeImg(0);
        this.setCreativeMsg(this.imgList);
      }
    },
    // 上传图片
    upload_img(file) {
      this.uploadImg(file)
        .then((data) => {
          if (data.status == 'error') {
            this.$notify.error({
              title: '错误',
              message: `${file.file.name}上传失败`,
              duration: 0,
            });
          } else {
            if (this.edit || this.isFbPageAd) {
              this.uploadVideoFb(data.id);
            } else {
              data.videoStatus = 'processing';
              data.source_id = data.id;
              this.imgList = this.imgList.concat({ ...data, videoStatus: 'processing' }).slice(0, 30);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.imgCon {
  width: 220px;
  height: 220px;
  border: 1px solid #e4e4e4;
  border-radius: 4px;
  text-align: center;
  margin-bottom: 15px;
  margin-right: 8px;
  // overflow: hidden;
  position: relative;
  margin: 5px;

  video {
    width: 100%;
    height: 100%;
  }

  .delete {
    position: absolute;
    top: -5px;
    right: -5px;
    z-index: 2;
    font-size: 18px;
    cursor: pointer;
    background: #fff;
    border-radius: 50%;
  }
}

.imgMsg {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;

  .imgList {
    flex: 1;
    overflow-x: auto;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    overflow-y: hidden;

    .imgCon {
      flex-shrink: 0;
      border: 3px solid transparent;
      &.active {
        border-color: #409eff;
      }
    }
  }
}

.imgForm {
  .el-form-item {
    margin-bottom: 10px;
  }
}
</style>
